import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/analytics'
import 'firebase/storage'

// Initialize Firebase
let config = {
    apiKey: "AIzaSyB5goTfWmoOnBiGRXpgqazboOro6RCoAas",
    authDomain: "pacaya-afef0.firebaseapp.com",
    projectId: "pacaya-afef0",
    storageBucket: "pacaya-afef0.appspot.com",
    messagingSenderId: "1057179924290",
    appId: "1:1057179924290:web:4e09d24119b20f3f2afadb",
    measurementId: "G-SGGKNNCR8K"
}

firebase.initializeApp(config)
firebase.analytics()

// utils
const db = firebase.firestore()
const storage = firebase.storage()
const FieldValue = firebase.firestore.FieldValue
const Timestamp = firebase.firestore.Timestamp
const auth = firebase.auth()

export {
    db,
    auth,
    FieldValue,
    Timestamp,
    storage
}
